<template>
	<div>
		<div v-if="!addOrEditReady">
			<LoadingSpinner class="col-12" />
		</div>
		<div v-else>
			<div v-if="criteres_local.length > 2 && Object.keys(recap).length > 0" class="row">
				<div class="d-none d-lg-block d-xl-block">
					<button href="#" @click="goBack()" class="btn btn-primary btn-prev mb-2"><font-awesome-icon :icon="['fal', 'long-arrow-left']" /> {{ $t('pax.retour') }}</button>
				</div>
				<div class="col mt-lg-4">
					<div class="row no-gutters align-items-center mb-3">
						<div class="col col-lg-12">
							<a href="#" @click="goBack()" class="btn btn-primary btn-retour mb-3 d-lg-none"><font-awesome-icon :icon="['fal', 'long-arrow-left']" /> {{ $t('pax.retour') }}</a>
							<div class="row ml-1">
								<h3 class="mb-0 mb-lg-3">4. {{ $t('pax.comparaison') }}</h3>
							</div>
						</div>
					</div>
					<div class="row no-gutters align-items-center mb-3">
						<div class="col-2 mr-auto"></div>
						<div :class="'col-'+col_stallion+' text-center'" v-for="stallion in stallions_local" :key="stallion.horse_id">
							<span class="stallionTabName" @click.prevent="displayDetailsCroisement(stallion)">{{ stallion.horse_nom }}</span>
						</div>
					</div>
					<div class="tabLine" v-for="critere in criteres_local" :key="critere.criteres_id">
						<div class="row no-gutters align-items-center">
							<div class="col-2 mr-auto">
								{{ critere.critereslang_label }}
							</div>
							<div :class="'col-'+col_stallion+' text-center'" v-for="stallion in stallions_local" :key="stallion.horse_id">
								<span class="bullet grey" v-if="isNaN(recap[stallion.horse_id][critere.criteres_id])" @click.prevent="displayDetailsCroisement(stallion)"></span>
								<span class="bullet red" v-else-if="parseInt(recap[stallion.horse_id][critere.criteres_id]) < critere.criteres_red" @click.prevent="displayDetailsCroisement(stallion)">
								</span>
								<span class="bullet green" v-else-if="parseInt(recap[stallion.horse_id][critere.criteres_id]) >= critere.criteres_green" @click.prevent="displayDetailsCroisement(stallion)">
								</span>
								<span class="bullet orange" v-else-if="parseInt(recap[stallion.horse_id][critere.criteres_id]) >= critere.criteres_red && parseInt(recap[stallion.horse_id][critere.criteres_id]) < critere.criteres_green" @click.prevent="displayDetailsCroisement(stallion)">
								</span>
								<span class="bullet grey" v-else @click.prevent="displayDetailsCroisement(stallion)"></span>
							</div>
						</div>
					</div>
					<div class="row no-gutters align-items-center my-3">
						<div class="col-2 mr-auto">
							
						</div>
				
						<div :class="'col-'+col_stallion+' text-center px-1'" v-for="stallion in stallions_local" :key="stallion.horse_id">
							<div v-if="isLg || isXl" class="row no-gutters tools mt-4 modal-pax justify-content-center">
								<div class="col-12 py-1 mt-md-2 mt-xxl-0">
									<router-link :to="{ name: 'ContractNew', params: {stallion_id: mare.horse_id, mare_id: stallion.horse_id}}" target="_blank" class="modal-btn"><span><font-awesome-icon :icon="['far', 'money-check-edit']" /><b>{{ $t('pax.reserver_en_ligne') }}</b></span></router-link>
								</div>
								<div class="col-12 py-1">
									<a class="modal-btn" :href="'https://www.gfeweb.com/' + lang + '/?redirect_to_fiche=1&horse_nom=' + mare.horse_nom + '&horse_sire=' + mare.horse_sire " target="_blank"><span><i>{{mare.horse_nom}}</i> <b>{{ $t('pax.voir_fiche_etalon') }}</b></span></a>
								</div>
								<div class="col-12 py-1">
									<a class="modal-btn" href="#" @click.prevent="goToPaxGraphique(stallion)"><span><font-awesome-icon :icon="['far', 'chart-area']" /> <b>{{ $t('pax.go_to_pax_graphique') }}</b></span></a>
								</div>
							</div>

							<button v-if="!isLg && !isXl" class="btn btn-dark rounded-pill w-100 d-block" @click.prevent="displayDetailsCroisement(stallion)">{{ $t('pax.choisir') }}</button>
						</div>
					</div>
				</div>

				<b-modal dialog-class="modal-pax" ref="modal_details" hide-footer>
					<template v-slot:modal-title class="d-flex">
						{{ $t('pax.detail_croisement') }}
						<button class="input-group-text px-3 modal-header-btn" @click="openIntroduction"><font-awesome-icon :icon="['fas', 'info']" /></button>
						<span class="elipsis">{{ stallion_choose.horse_nom }} X {{ mare.horse_nom }}</span>
					</template>

					<template v-if="stallion_choose != null">
						<div class="tabLine" v-for="critere in criteres_local" :key="critere.criteres_id">
							<div class="row no-gutters align-items-center" >
								<div class="col-6">
									{{ critere.critereslang_label }}
								</div>
								<div class="col text-center">
									<span class="bullet red" v-if="parseInt(recap[stallion_choose.horse_id][critere.criteres_id]) < critere.criteres_red">
									</span>
									<span class="bullet green" v-else-if="parseInt(recap[stallion_choose.horse_id][critere.criteres_id]) >= critere.criteres_green">
									</span>
									<span class="bullet orange" v-else-if="parseInt(recap[stallion_choose.horse_id][critere.criteres_id]) >= critere.criteres_red && parseInt(recap[stallion_choose.horse_id][critere.criteres_id]) < critere.criteres_green">
									</span>
									<span class="bullet grey" v-else></span>
								</div>
							</div>
						</div>

						<div class="row no-gutters tools mt-4">
							<div class="col-xxl-4 col-xl-6 col-lg-4 col-md-4 col-12 px-1">
								<a class="modal-btn" :href="'https://www.gfeweb.com/' + lang + '/?redirect_to_fiche=1&horse_nom=' + mare.horse_nom + '&horse_sire=' + mare.horse_sire " target="_blank"><span><i>{{ mare.horse_nom }}</i> <b>{{ $t('pax.voir_fiche_etalon') }}</b></span></a>
							</div>
							<div class="col-xxl-4 col-xl-6 col-lg-4 col-md-4 col-12 px-1 mt-3 mt-md-0">
								<router-link :to="{ name: 'ContractNew', params: {stallion_id: mare.horse_id, mare_id: stallion_choose.horse_id}}" target="_blank" class="modal-btn"><span><font-awesome-icon :icon="['far', 'money-check-edit']" /><b>{{ $t('pax.reserver_en_ligne') }}</b></span></router-link>
							</div>
							<div class="col-xxl-4 col-xl-6 col-lg-4 col-md-4 col-12 px-1 mt-3 mt-md-0">
								<a class="modal-btn" href="#" @click.prevent="goToPaxGraphique(stallion_choose)"><span><font-awesome-icon :icon="['far', 'chart-area']" /> <b>{{ $t('pax.go_to_pax_graphique') }}</b></span></a>
							</div>
						</div>
					</template>
				</b-modal>


			</div>
			<template v-else> 
				<p>{{ $t('pax.completer_criteres_voir_compatibilite') }}</p>
			</template>

			<b-modal dialog-class="modal-pax" ref="modal" hide-footer>
				<template v-slot:modal-title>
					{{ $t('pax.pax_express') }}
				</template>

				<div v-html="text_intro"></div>
			</b-modal>
		</div>
	</div>
</template>
<script type="text/javascript">
    import { EventBus } from 'EventBus'
	import Navigation from "@/mixins/Navigation.js"
	import PaxMixin from "@/mixins/Pax.js"
	import Tools from "@/mixins/Tools.js";
	import Window from '@/mixins/Window.js'
	import _cloneDeep from 'lodash/cloneDeep';

	export default {
		name: "ResultCompatibiliteVReverse",
		props: {
			criteres: {
				type: Array,
				default: () => ( [] )
			},
			stallions: {
				type: Array,
				default: () => ( [] )
			},
			mare: {
				type: Object,
				default: () => ( null )
			},
		},
		mixins: [Navigation, PaxMixin, Tools, Window],
		data () {
			return {
				addOrEditReady: false,
				stallions_local: [],
				criteres_local: [],
				lang: 'fr',
				stallion_choose: null,
				recap: {},
				text_intro: ''
			}
		},
		created() {
			this.init_component()
		},
		methods: {
			async init_component() {
				this.addOrEditReady = false
				this.stallions_local = _cloneDeep(this.stallions.filter(st => st))
				this.criteres_local = _cloneDeep(this.criteres)

				this.calculCompatibilite()

				this.lang = this.getConfig('lang')
				this.text_intro = this.getConfig('translations')['pax_reverse'][this.lang]

				this.addOrEditReady = true
			},

			async calculCompatibilite() {
				this.recap = await this.paxCalculCompatiblite(this.stallions_local, this.criteres_local)
			},

			async displayDetailsCroisement(stallion) {
				this.stallion_choose = stallion
				this.$refs.modal_details.show()
			},

			async goBack() {
				this.$emit("update:step_label", "mares_selected")
			},

			openIntroduction() {
				this.$refs.modal_details.hide()
				this.$refs.modal.show()
			},

			goToPaxGraphique(mare) {
				this.$refs.modal_details.hide()
				this.$emit("update:mare_graphique", mare)
				this.$emit('update:step_label', "pax_graphique")
			}
			
		},
		computed: {
			col_stallion() {
				const nb_stallions = this.stallions.length
				if(nb_stallions == 1) return 9
				if(nb_stallions == 2) return 5
				if(nb_stallions == 3) return 3
				return 3
			}
		},
		watch: {
			stallions(val) {
				this.stallions_local = _cloneDeep(val.filter(st => st))
				this.calculCompatibilite()
			},
			'criteres': {
				deep: true,
				handler(val){
					this.criteres_local = _cloneDeep(val)
					this.calculCompatibilite()
				}
			},
			show(val) {
				if(val) {
					window.scrollTo({ top: 250, behavior: 'smooth' });
				}
			}
		},
		components: {
			LoadingSpinner : () => import('GroomyRoot/components/Logos/LoadingSpinner_35'),
		}
	}
</script>
